import React from 'react'
import Layout from 'components/layout'
import TarifsBloc from 'components/TarifsBloc/'
import { enumTheme } from 'config'
import { getCurrentLang } from 'utils'
import { getPageTitle, getPageSlug } from '../slugs'

export default props => {
  const {
    data: {
      tarifsTitrePage,
      tarifsSoustitrePage,
      listeTarifs,
      conditionsDeLocation,
      phraseContact,
      texteBoutonContact,
      texteSecondBoutonContact,
      lienSecondBoutonContact,
      cartesBancairesAcceptees,
      illustrationPageTarifs,
    },
    slugs,
    lang
  } = props

  return (
    <Layout
      slugs={slugs}
      lang={lang}
      pageTitle={getPageTitle('tarifs', lang)}
      headerScroll={0}
      footerTheme={enumTheme.WHITE}
      socialMediaIsDark={false}
      socialMediaScroll={0}
      socialMediaOffset={0}
      baseUrl={getCurrentLang(props.location.pathname)}
      currentPage={ props.currentPage }
    >
      <TarifsBloc
        titleBloc={tarifsTitrePage}
        subTitleBloc={tarifsSoustitrePage}
        listTarifs={JSON.parse(listeTarifs)}
        contentRight={conditionsDeLocation}
        descriptionText={phraseContact}
        textButton={texteBoutonContact}
        textSecondButton={texteSecondBoutonContact}
        linkSecondButton={lienSecondBoutonContact}
        linkButton={getPageSlug('contact', lang)}
        isAcceptedCards={cartesBancairesAcceptees}
        background={illustrationPageTarifs}
        baseUrl={getCurrentLang(props.location.pathname)}
      />
    </Layout>
  )
}
